// File#: _1_menu
// Usage: codyhouse.co/license
(function() {
	var Menu = function(element) {
		this.element = element;
		this.elementId = this.element.getAttribute('id');
		this.menuItems = this.element.getElementsByClassName('js-menu__content');
		this.trigger = document.querySelectorAll('[aria-controls="'+this.elementId+'"]');
		this.selectedTrigger = false;
		this.menuIsOpen = false;
		this.initMenu();
		this.initMenuEvents();
	};	

	Menu.prototype.initMenu = function() {
		// init aria-labels
		for(var i = 0; i < this.trigger.length; i++) {
			Util.setAttributes(this.trigger[i], {'aria-expanded': 'false', 'aria-haspopup': 'true'});
		}
		// init tabindex
		for(var i = 0; i < this.menuItems.length; i++) {
			this.menuItems[i].setAttribute('tabindex', '0');
		}
	};

	Menu.prototype.initMenuEvents = function() {
		var self = this;
		for(var i = 0; i < this.trigger.length; i++) {(function(i){
			self.trigger[i].addEventListener('click', function(event){
				event.preventDefault();
				// if the menu had been previously opened by another trigger element -> close it first and reopen in the right position
				if(Util.hasClass(self.element, 'menu--is-visible') && self.selectedTrigger !=  self.trigger[i]) {
					self.toggleMenu(false, false); // close menu
				}
				// toggle menu
				self.selectedTrigger = self.trigger[i];
				self.toggleMenu(!Util.hasClass(self.element, 'menu--is-visible'), true);
			});
		})(i);}
		
		// keyboard events
		this.element.addEventListener('keydown', function(event) {
			// use up/down arrow to navigate list of menu items
			if( !Util.hasClass(event.target, 'js-menu__content') ) return;
			if( (event.keyCode && event.keyCode == 40) || (event.key && event.key.toLowerCase() == 'arrowdown') ) {
				self.navigateItems(event, 'next');
			} else if( (event.keyCode && event.keyCode == 38) || (event.key && event.key.toLowerCase() == 'arrowup') ) {
				self.navigateItems(event, 'prev');
			}
		});
	};

	Menu.prototype.toggleMenu = function(bool, moveFocus) {
		var self = this;
		// toggle menu visibility
		Util.toggleClass(this.element, 'menu--is-visible', bool);
		this.menuIsOpen = bool;
		if(bool) {
			this.selectedTrigger.setAttribute('aria-expanded', 'true');
			Util.moveFocus(this.menuItems[0]);
			this.element.addEventListener("transitionend", function(event) {Util.moveFocus(self.menuItems[0]);}, {once: true});
			// position the menu element
			this.positionMenu();
			// add class to menu trigger
			Util.addClass(this.selectedTrigger, 'menu-control--active');
		} else if(this.selectedTrigger) {
			this.selectedTrigger.setAttribute('aria-expanded', 'false');
			if(moveFocus) Util.moveFocus(this.selectedTrigger);
			// remove class from menu trigger
			Util.removeClass(this.selectedTrigger, 'menu-control--active');
			this.selectedTrigger = false;
		}
	};

	Menu.prototype.positionMenu = function(event, direction) {
		var selectedTriggerPosition = this.selectedTrigger.getBoundingClientRect(),
			menuOnTop = (window.innerHeight - selectedTriggerPosition.bottom) < selectedTriggerPosition.top;
			// menuOnTop = window.innerHeight < selectedTriggerPosition.bottom + this.element.offsetHeight;
			
		var left = selectedTriggerPosition.left,
			right = (window.innerWidth - selectedTriggerPosition.right),
			isRight = (window.innerWidth < selectedTriggerPosition.left + this.element.offsetWidth);

		var horizontal = isRight ? 'right: '+right+'px;' : 'left: '+left+'px;',
			vertical = menuOnTop
				? 'bottom: '+(window.innerHeight - selectedTriggerPosition.top)+'px;'
				: 'top: '+selectedTriggerPosition.bottom+'px;';
		// check right position is correct -> otherwise set left to 0
		if( isRight && (right + this.element.offsetWidth) > window.innerWidth) horizontal = 'left: '+ parseInt((window.innerWidth - this.element.offsetWidth)/2)+'px;';
		var maxHeight = menuOnTop ? selectedTriggerPosition.top - 20 : window.innerHeight - selectedTriggerPosition.bottom - 20;
		this.element.setAttribute('style', horizontal + vertical +'max-height:'+Math.floor(maxHeight)+'px;');
	};

	Menu.prototype.navigateItems = function(event, direction) {
		event.preventDefault();
		var index = Util.getIndexInArray(this.menuItems, event.target),
			nextIndex = direction == 'next' ? index + 1 : index - 1;
		if(nextIndex < 0) nextIndex = this.menuItems.length - 1;
		if(nextIndex > this.menuItems.length - 1) nextIndex = 0;
		Util.moveFocus(this.menuItems[nextIndex]);
	};

	Menu.prototype.checkMenuFocus = function() {
		var menuParent = document.activeElement.closest('.js-menu');
		if (!menuParent || !this.element.contains(menuParent)) this.toggleMenu(false, false);
	};

	Menu.prototype.checkMenuClick = function(target) {
		if( !this.element.contains(target) && !target.closest('[aria-controls="'+this.elementId+'"]')) this.toggleMenu(false);
	};

	window.Menu = Menu;

	//initialize the Menu objects
	var menus = document.getElementsByClassName('js-menu');
	if( menus.length > 0 ) {
		var menusArray = [];
		for( var i = 0; i < menus.length; i++) {
			(function(i){menusArray.push(new Menu(menus[i]));})(i);
		}

		// listen for key events
		window.addEventListener('keyup', function(event){
			if( event.keyCode && event.keyCode == 9 || event.key && event.key.toLowerCase() == 'tab' ) {
				//close menu if focus is outside menu element
				menusArray.forEach(function(element){
					element.checkMenuFocus();
				});
			} else if( event.keyCode && event.keyCode == 27 || event.key && event.key.toLowerCase() == 'escape' ) {
				// close menu on 'Esc'
				menusArray.forEach(function(element){
					element.toggleMenu(false, false);
				});
			} 
		});
		// close menu when clicking outside it
		window.addEventListener('click', function(event){
			menusArray.forEach(function(element){
				element.checkMenuClick(event.target);
			});
		});
		// on resize -> close all menu elements
		window.addEventListener('resize', function(event){
			menusArray.forEach(function(element){
				element.toggleMenu(false, false);
			});
		});
		// on scroll -> close all menu elements
		window.addEventListener('scroll', function(event){
			menusArray.forEach(function(element){
				if(element.menuIsOpen) element.toggleMenu(false, false);
			});
		});
	}
}());


// File#: _2_menu-bar
// Usage: codyhouse.co/license
(function() {
	var MenuBar = function(element) {
	  this.element = element;
	  this.items = Util.getChildrenByClassName(this.element, 'menu-bar__item');
	  this.mobHideItems = this.element.getElementsByClassName('menu-bar__item--hide');
	  this.moreItemsTrigger = this.element.getElementsByClassName('js-menu-bar__trigger');
	  initMenuBar(this);
	};
  
	function initMenuBar(menu) {
	  setMenuTabIndex(menu); // set correct tabindexes for menu item
	  initMenuBarMarkup(menu); // create additional markup
	  checkMenuLayout(menu); // set menu layout
	  Util.addClass(menu.element, 'menu-bar--loaded'); // reveal menu
  
	  // custom event emitted when window is resized
	  menu.element.addEventListener('update-menu-bar', function(event){
		checkMenuLayout(menu);
		if(menu.menuInstance) menu.menuInstance.toggleMenu(false, false); // close dropdown
	  });
  
	  // keyboard events 
	  // open dropdown when pressing Enter on trigger element
	  if(menu.moreItemsTrigger.length > 0) {
		menu.moreItemsTrigger[0].addEventListener('keydown', function(event) {
		  if( (event.keyCode && event.keyCode == 13) || (event.key && event.key.toLowerCase() == 'enter') ) {
			if(!menu.menuInstance) return;
			menu.menuInstance.selectedTrigger = menu.moreItemsTrigger[0];
			menu.menuInstance.toggleMenu(!Util.hasClass(menu.subMenu, 'menu--is-visible'), true);
		  }
		});
  
		// close dropdown on esc
		menu.subMenu.addEventListener('keydown', function(event) {
		  if((event.keyCode && event.keyCode == 27) || (event.key && event.key.toLowerCase() == 'escape')) { // close submenu on esc
			if(menu.menuInstance) menu.menuInstance.toggleMenu(false, true);
		  }
		});
	  }
	  
	  // navigate menu items using left/right arrows
	  menu.element.addEventListener('keydown', function(event) {
		if( (event.keyCode && event.keyCode == 39) || (event.key && event.key.toLowerCase() == 'arrowright') ) {
		  navigateItems(menu.items, event, 'next');
		} else if( (event.keyCode && event.keyCode == 37) || (event.key && event.key.toLowerCase() == 'arrowleft') ) {
		  navigateItems(menu.items, event, 'prev');
		}
	  });
	};
  
	function setMenuTabIndex(menu) { // set tabindexes for the menu items to allow keyboard navigation
	  var nextItem = false;
	  for(var i = 0; i < menu.items.length; i++ ) {
		if(i == 0 || nextItem) menu.items[i].setAttribute('tabindex', '0');
		else menu.items[i].setAttribute('tabindex', '-1');
		if(i == 0 && menu.moreItemsTrigger.length > 0) nextItem = true;
		else nextItem = false;
	  }
	};
  
	function initMenuBarMarkup(menu) {
	  if(menu.mobHideItems.length == 0 ) { // no items to hide on mobile - remove trigger
		if(menu.moreItemsTrigger.length > 0) menu.element.removeChild(menu.moreItemsTrigger[0]);
		return;
	  }
  
	  if(menu.moreItemsTrigger.length == 0) return;
  
	  // create the markup for the Menu element
	  var content = '';
	  menu.menuControlId = 'submenu-bar-'+Date.now();
	  for(var i = 0; i < menu.mobHideItems.length; i++) {
		var item = menu.mobHideItems[i].cloneNode(true),
		  svg = item.getElementsByTagName('svg')[0],
		  label = item.getElementsByClassName('menu-bar__label')[0];
  
		svg.setAttribute('class', 'icon menu__icon');
		content = content + '<li role="menuitem"><span class="menu__content js-menu__content">'+svg.outerHTML+'<span>'+label.innerHTML+'</span></span></li>';
	  }
  
	  Util.setAttributes(menu.moreItemsTrigger[0], {'role': 'button', 'aria-expanded': 'false', 'aria-controls': menu.menuControlId, 'aria-haspopup': 'true'});
  
	  var subMenu = document.createElement('menu'),
		customClass = menu.element.getAttribute('data-menu-class');
	  Util.setAttributes(subMenu, {'id': menu.menuControlId, 'class': 'menu js-menu '+customClass});
	  subMenu.innerHTML = content;
	  document.body.appendChild(subMenu);
  
	  menu.subMenu = subMenu;
	  menu.subItems = subMenu.getElementsByTagName('li');
  
	  menu.menuInstance = new Menu(menu.subMenu); // this will handle the dropdown behaviour
	};
  
	function checkMenuLayout(menu) { // switch from compressed to expanded layout and viceversa
	  var layout = getComputedStyle(menu.element, ':before').getPropertyValue('content').replace(/\'|"/g, '');
	  Util.toggleClass(menu.element, 'menu-bar--collapsed', layout == 'collapsed');
	};
  
	function navigateItems(list, event, direction, prevIndex) { // keyboard navigation among menu items
	  event.preventDefault();
	  var index = (typeof prevIndex !== 'undefined') ? prevIndex : Util.getIndexInArray(list, event.target),
		nextIndex = direction == 'next' ? index + 1 : index - 1;
	  if(nextIndex < 0) nextIndex = list.length - 1;
	  if(nextIndex > list.length - 1) nextIndex = 0;
	  // check if element is visible before moving focus
	  (list[nextIndex].offsetParent === null) ? navigateItems(list, event, direction, nextIndex) : Util.moveFocus(list[nextIndex]);
	};
  
	function checkMenuClick(menu, target) { // close dropdown when clicking outside the menu element
	  if(menu.menuInstance && !menu.moreItemsTrigger[0].contains(target) && !menu.subMenu.contains(target)) menu.menuInstance.toggleMenu(false, false);
	};
  
	// init MenuBars objects
	var menuBars = document.getElementsByClassName('js-menu-bar');
	if( menuBars.length > 0 ) {
	  var j = 0,
		menuBarArray = [];
	  for( var i = 0; i < menuBars.length; i++) {
		var beforeContent = getComputedStyle(menuBars[i], ':before').getPropertyValue('content');
		if(beforeContent && beforeContent !='' && beforeContent !='none') {
		  (function(i){menuBarArray.push(new MenuBar(menuBars[i]));})(i);
		  j = j + 1;
		}
	  }
	  
	  if(j > 0) {
		var resizingId = false,
		  customEvent = new CustomEvent('update-menu-bar');
		// update Menu Bar layout on resize  
		window.addEventListener('resize', function(event){
		  clearTimeout(resizingId);
		  resizingId = setTimeout(doneResizing, 150);
		});
  
		// close menu when clicking outside it
		window.addEventListener('click', function(event){
		  menuBarArray.forEach(function(element){
			checkMenuClick(element, event.target);
		  });
		});
  
		function doneResizing() {
		  for( var i = 0; i < menuBars.length; i++) {
			(function(i){menuBars[i].dispatchEvent(customEvent)})(i);
		  };
		};
	  }
	}
  }());


  // File#: _1_swipe-content
(function() {
	var SwipeContent = function(element) {
		this.element = element;
		this.delta = [false, false];
		this.dragging = false;
		this.intervalId = false;
		initSwipeContent(this);
	};

	function initSwipeContent(content) {
		content.element.addEventListener('mousedown', handleEvent.bind(content));
		content.element.addEventListener('touchstart', handleEvent.bind(content));
	};

	function initDragging(content) {
		//add event listeners
		content.element.addEventListener('mousemove', handleEvent.bind(content));
		content.element.addEventListener('touchmove', handleEvent.bind(content));
		content.element.addEventListener('mouseup', handleEvent.bind(content));
		content.element.addEventListener('mouseleave', handleEvent.bind(content));
		content.element.addEventListener('touchend', handleEvent.bind(content));
	};

	function cancelDragging(content) {
		//remove event listeners
		if(content.intervalId) {
			(!window.requestAnimationFrame) ? clearInterval(content.intervalId) : window.cancelAnimationFrame(content.intervalId);
			content.intervalId = false;
		}
		content.element.removeEventListener('mousemove', handleEvent.bind(content));
		content.element.removeEventListener('touchmove', handleEvent.bind(content));
		content.element.removeEventListener('mouseup', handleEvent.bind(content));
		content.element.removeEventListener('mouseleave', handleEvent.bind(content));
		content.element.removeEventListener('touchend', handleEvent.bind(content));
	};

	function handleEvent(event) {
		switch(event.type) {
			case 'mousedown':
			case 'touchstart':
				startDrag(this, event);
				break;
			case 'mousemove':
			case 'touchmove':
				drag(this, event);
				break;
			case 'mouseup':
			case 'mouseleave':
			case 'touchend':
				endDrag(this, event);
				break;
		}
	};

	function startDrag(content, event) {
		content.dragging = true;
		// listen to drag movements
		initDragging(content);
		content.delta = [parseInt(unify(event).clientX), parseInt(unify(event).clientY)];
		// emit drag start event
		emitSwipeEvents(content, 'dragStart', content.delta, event.target);
	};

	function endDrag(content, event) {
		cancelDragging(content);
		// credits: https://css-tricks.com/simple-swipe-with-vanilla-javascript/
		var dx = parseInt(unify(event).clientX), 
	    dy = parseInt(unify(event).clientY);
	  
	  // check if there was a left/right swipe
		if(content.delta && (content.delta[0] || content.delta[0] === 0)) {
	    var s = getSign(dx - content.delta[0]);
			
			if(Math.abs(dx - content.delta[0]) > 30) {
				(s < 0) ? emitSwipeEvents(content, 'swipeLeft', [dx, dy]) : emitSwipeEvents(content, 'swipeRight', [dx, dy]);	
			}
	    
	    content.delta[0] = false;
	  }
		// check if there was a top/bottom swipe
	  if(content.delta && (content.delta[1] || content.delta[1] === 0)) {
	  	var y = getSign(dy - content.delta[1]);

	  	if(Math.abs(dy - content.delta[1]) > 30) {
	    	(y < 0) ? emitSwipeEvents(content, 'swipeUp', [dx, dy]) : emitSwipeEvents(content, 'swipeDown', [dx, dy]);
	    }

	    content.delta[1] = false;
	  }
		// emit drag end event
	  emitSwipeEvents(content, 'dragEnd', [dx, dy]);
	  content.dragging = false;
	};

	function drag(content, event) {
		if(!content.dragging) return;
		// emit dragging event with coordinates
		(!window.requestAnimationFrame) 
			? content.intervalId = setTimeout(function(){emitDrag.bind(content, event);}, 250) 
			: content.intervalId = window.requestAnimationFrame(emitDrag.bind(content, event));
	};

	function emitDrag(event) {
		emitSwipeEvents(this, 'dragging', [parseInt(unify(event).clientX), parseInt(unify(event).clientY)]);
	};

	function unify(event) { 
		// unify mouse and touch events
		return event.changedTouches ? event.changedTouches[0] : event; 
	};

	function emitSwipeEvents(content, eventName, detail, el) {
		var trigger = false;
		if(el) trigger = el;
		// emit event with coordinates
		var event = new CustomEvent(eventName, {detail: {x: detail[0], y: detail[1], origin: trigger}});
		content.element.dispatchEvent(event);
	};

	function getSign(x) {
		if(!Math.sign) {
			return ((x > 0) - (x < 0)) || +x;
		} else {
			return Math.sign(x);
		}
	};

	window.SwipeContent = SwipeContent;
	
	//initialize the SwipeContent objects
	var swipe = document.getElementsByClassName('js-swipe-content');
	if( swipe.length > 0 ) {
		for( var i = 0; i < swipe.length; i++) {
			(function(i){new SwipeContent(swipe[i]);})(i);
		}
	}
}());

// File#: _2_slideshow
// Usage: codyhouse.co/license
(function() {
	var Slideshow = function(opts) {
		this.options = slideshowAssignOptions(Slideshow.defaults , opts);
		this.element = this.options.element;
		this.items = this.element.getElementsByClassName('js-slideshow__item');
		this.controls = this.element.getElementsByClassName('js-slideshow__control'); 
		this.selectedSlide = 0;
		this.autoplayId = false;
		this.autoplayPaused = false;
		this.navigation = false;
		this.navCurrentLabel = false;
		this.ariaLive = false;
		this.moveFocus = false;
		this.animating = false;
		this.supportAnimation = Util.cssSupports('transition');
		this.animationOff = (!Util.hasClass(this.element, 'slideshow--transition-fade') && !Util.hasClass(this.element, 'slideshow--transition-slide') && !Util.hasClass(this.element, 'slideshow--transition-prx'));
		this.animationType = Util.hasClass(this.element, 'slideshow--transition-prx') ? 'prx' : 'slide';
		this.animatingClass = 'slideshow--is-animating';
		initSlideshow(this);
		initSlideshowEvents(this);
		initAnimationEndEvents(this);
	};

	Slideshow.prototype.showNext = function() {
		showNewItem(this, this.selectedSlide + 1, 'next');
	};

	Slideshow.prototype.showPrev = function() {
		showNewItem(this, this.selectedSlide - 1, 'prev');
	};

	Slideshow.prototype.showItem = function(index) {
		showNewItem(this, index, false);
	};

	Slideshow.prototype.startAutoplay = function() {
		var self = this;
		if(this.options.autoplay && !this.autoplayId && !this.autoplayPaused) {
			self.autoplayId = setInterval(function(){
				self.showNext();
			}, self.options.autoplayInterval);
		}
	};

	Slideshow.prototype.pauseAutoplay = function() {
		var self = this;
		if(this.options.autoplay) {
			clearInterval(self.autoplayId);
			self.autoplayId = false;
		}
	};

	function slideshowAssignOptions(defaults, opts) {
		// initialize the object options
		var mergeOpts = {};
		mergeOpts.element = (typeof opts.element !== "undefined") ? opts.element : defaults.element;
		mergeOpts.navigation = (typeof opts.navigation !== "undefined") ? opts.navigation : defaults.navigation;
		mergeOpts.autoplay = (typeof opts.autoplay !== "undefined") ? opts.autoplay : defaults.autoplay;
		mergeOpts.autoplayInterval = (typeof opts.autoplayInterval !== "undefined") ? opts.autoplayInterval : defaults.autoplayInterval;
		mergeOpts.swipe = (typeof opts.swipe !== "undefined") ? opts.swipe : defaults.swipe;
		return mergeOpts;
	};

	function initSlideshow(slideshow) { // basic slideshow settings
		// if no slide has been selected -> select the first one
		if(slideshow.element.getElementsByClassName('slideshow__item--selected').length < 1) Util.addClass(slideshow.items[0], 'slideshow__item--selected');
		slideshow.selectedSlide = Util.getIndexInArray(slideshow.items, slideshow.element.getElementsByClassName('slideshow__item--selected')[0]);
		// create an element that will be used to announce the new visible slide to SR
		var srLiveArea = document.createElement('div');
		Util.setAttributes(srLiveArea, {'class': 'sr-only js-slideshow__aria-live', 'aria-live': 'polite', 'aria-atomic': 'true'});
		slideshow.element.appendChild(srLiveArea);
		slideshow.ariaLive = srLiveArea;
	};

	function initSlideshowEvents(slideshow) {
		// if slideshow navigation is on -> create navigation HTML and add event listeners
		if(slideshow.options.navigation) {
			// check if navigation has already been included
			if(slideshow.element.getElementsByClassName('js-slideshow__navigation').length == 0) {
				var navigation = document.createElement('ol'),
					navChildren = '';

				var navClasses = 'slideshow__navigation js-slideshow__navigation';
				if(slideshow.items.length <= 1) {
					navClasses = navClasses + ' is-hidden';
				} 
				
				navigation.setAttribute('class', navClasses);
				for(var i = 0; i < slideshow.items.length; i++) {
					var className = (i == slideshow.selectedSlide) ? 'class="slideshow__nav-item slideshow__nav-item--selected js-slideshow__nav-item"' :  'class="slideshow__nav-item js-slideshow__nav-item"',
						navCurrentLabel = (i == slideshow.selectedSlide) ? '<span class="sr-only js-slideshow__nav-current-label">Current Item</span>' : '';
					navChildren = navChildren + '<li '+className+'><button class="reset"><span class="sr-only">'+ (i+1) + '</span>'+navCurrentLabel+'</button></li>';
				}
				navigation.innerHTML = navChildren;
				slideshow.element.appendChild(navigation);
			}
			
			slideshow.navCurrentLabel = slideshow.element.getElementsByClassName('js-slideshow__nav-current-label')[0]; 
			slideshow.navigation = slideshow.element.getElementsByClassName('js-slideshow__nav-item');

			var dotsNavigation = slideshow.element.getElementsByClassName('js-slideshow__navigation')[0];

			dotsNavigation.addEventListener('click', function(event){
				navigateSlide(slideshow, event, true);
			});
			dotsNavigation.addEventListener('keyup', function(event){
				navigateSlide(slideshow, event, (event.key.toLowerCase() == 'enter'));
			});
		}
		// slideshow arrow controls
		if(slideshow.controls.length > 0) {
			// hide controls if one item available
			if(slideshow.items.length <= 1) {
				Util.addClass(slideshow.controls[0], 'is-hidden');
				Util.addClass(slideshow.controls[1], 'is-hidden');
			}
			slideshow.controls[0].addEventListener('click', function(event){
				event.preventDefault();
				slideshow.showPrev();
				updateAriaLive(slideshow);
			});
			slideshow.controls[1].addEventListener('click', function(event){
				event.preventDefault();
				slideshow.showNext();
				updateAriaLive(slideshow);
			});
		}
		// swipe events
		if(slideshow.options.swipe) {
			//init swipe
			new SwipeContent(slideshow.element);
			slideshow.element.addEventListener('swipeLeft', function(event){
				slideshow.showNext();
			});
			slideshow.element.addEventListener('swipeRight', function(event){
				slideshow.showPrev();
			});
		}
		// autoplay
		if(slideshow.options.autoplay) {
			slideshow.startAutoplay();
			// pause autoplay if user is interacting with the slideshow
			slideshow.element.addEventListener('mouseenter', function(event){
				slideshow.pauseAutoplay();
				slideshow.autoplayPaused = true;
			});
			slideshow.element.addEventListener('focusin', function(event){
				slideshow.pauseAutoplay();
				slideshow.autoplayPaused = true;
			});
			slideshow.element.addEventListener('mouseleave', function(event){
				slideshow.autoplayPaused = false;
				slideshow.startAutoplay();
			});
			slideshow.element.addEventListener('focusout', function(event){
				slideshow.autoplayPaused = false;
				slideshow.startAutoplay();
			});
		}
		// detect if external buttons control the slideshow
		var slideshowId = slideshow.element.getAttribute('id');
		if(slideshowId) {
			var externalControls = document.querySelectorAll('[data-controls="'+slideshowId+'"]');
			for(var i = 0; i < externalControls.length; i++) {
				(function(i){externalControlSlide(slideshow, externalControls[i]);})(i);
			}
		}
		// custom event to trigger selection of a new slide element
		slideshow.element.addEventListener('selectNewItem', function(event){
			// check if slide is already selected
			if(event.detail) {
				if(event.detail - 1 == slideshow.selectedSlide) return;
				showNewItem(slideshow, event.detail - 1, false);
			}
		});
	};

	function navigateSlide(slideshow, event, keyNav) { 
		// user has interacted with the slideshow navigation -> update visible slide
		var target = ( Util.hasClass(event.target, 'js-slideshow__nav-item') ) ? event.target : event.target.closest('.js-slideshow__nav-item');
		if(keyNav && target && !Util.hasClass(target, 'slideshow__nav-item--selected')) {
			slideshow.showItem(Util.getIndexInArray(slideshow.navigation, target));
			slideshow.moveFocus = true;
			updateAriaLive(slideshow);
		}
	};

	function initAnimationEndEvents(slideshow) {
		// remove animation classes at the end of a slide transition
		for( var i = 0; i < slideshow.items.length; i++) {
			(function(i){
				slideshow.items[i].addEventListener('animationend', function(){resetAnimationEnd(slideshow, slideshow.items[i]);});
				slideshow.items[i].addEventListener('transitionend', function(){resetAnimationEnd(slideshow, slideshow.items[i]);});
			})(i);
		}
	};

	function resetAnimationEnd(slideshow, item) {
		setTimeout(function(){ // add a delay between the end of animation and slideshow reset - improve animation performance
			if(Util.hasClass(item,'slideshow__item--selected')) {
				if(slideshow.moveFocus) Util.moveFocus(item);
				emitSlideshowEvent(slideshow, 'newItemVisible', slideshow.selectedSlide);
				slideshow.moveFocus = false;
			}
			Util.removeClass(item, 'slideshow__item--'+slideshow.animationType+'-out-left slideshow__item--'+slideshow.animationType+'-out-right slideshow__item--'+slideshow.animationType+'-in-left slideshow__item--'+slideshow.animationType+'-in-right');
			item.removeAttribute('aria-hidden');
			slideshow.animating = false;
			Util.removeClass(slideshow.element, slideshow.animatingClass); 
		}, 100);
	};

	function showNewItem(slideshow, index, bool) {
		if(slideshow.items.length <= 1) return;
		if(slideshow.animating && slideshow.supportAnimation) return;
		slideshow.animating = true;
		Util.addClass(slideshow.element, slideshow.animatingClass); 
		if(index < 0) index = slideshow.items.length - 1;
		else if(index >= slideshow.items.length) index = 0;
		var exitItemClass = getExitItemClass(slideshow, bool, slideshow.selectedSlide, index);
		var enterItemClass = getEnterItemClass(slideshow, bool, slideshow.selectedSlide, index);
		// transition between slides
		if(!slideshow.animationOff) Util.addClass(slideshow.items[slideshow.selectedSlide], exitItemClass);
		Util.removeClass(slideshow.items[slideshow.selectedSlide], 'slideshow__item--selected');
		slideshow.items[slideshow.selectedSlide].setAttribute('aria-hidden', 'true'); //hide to sr element that is exiting the viewport
		if(slideshow.animationOff) {
			Util.addClass(slideshow.items[index], 'slideshow__item--selected');
		} else {
			Util.addClass(slideshow.items[index], enterItemClass+' slideshow__item--selected');
		}
		// reset slider navigation appearance
		resetSlideshowNav(slideshow, index, slideshow.selectedSlide);
		slideshow.selectedSlide = index;
		// reset autoplay
		slideshow.pauseAutoplay();
		slideshow.startAutoplay();
		// reset controls/navigation color themes
		resetSlideshowTheme(slideshow, index);
		// emit event
		emitSlideshowEvent(slideshow, 'newItemSelected', slideshow.selectedSlide);
		if(slideshow.animationOff) {
			slideshow.animating = false;
			Util.removeClass(slideshow.element, slideshow.animatingClass);
		}
	};

	function getExitItemClass(slideshow, bool, oldIndex, newIndex) {
		var className = '';
		if(bool) {
			className = (bool == 'next') ? 'slideshow__item--'+slideshow.animationType+'-out-right' : 'slideshow__item--'+slideshow.animationType+'-out-left'; 
		} else {
			className = (newIndex < oldIndex) ? 'slideshow__item--'+slideshow.animationType+'-out-left' : 'slideshow__item--'+slideshow.animationType+'-out-right';
		}
		return className;
	};

	function getEnterItemClass(slideshow, bool, oldIndex, newIndex) {
		var className = '';
		if(bool) {
			className = (bool == 'next') ? 'slideshow__item--'+slideshow.animationType+'-in-right' : 'slideshow__item--'+slideshow.animationType+'-in-left'; 
		} else {
			className = (newIndex < oldIndex) ? 'slideshow__item--'+slideshow.animationType+'-in-left' : 'slideshow__item--'+slideshow.animationType+'-in-right';
		}
		return className;
	};

	function resetSlideshowNav(slideshow, newIndex, oldIndex) {
		if(slideshow.navigation) {
			Util.removeClass(slideshow.navigation[oldIndex], 'slideshow__nav-item--selected');
			Util.addClass(slideshow.navigation[newIndex], 'slideshow__nav-item--selected');
			slideshow.navCurrentLabel.parentElement.removeChild(slideshow.navCurrentLabel);
			slideshow.navigation[newIndex].getElementsByTagName('button')[0].appendChild(slideshow.navCurrentLabel);
		}
	};

	function resetSlideshowTheme(slideshow, newIndex) {
		var dataTheme = slideshow.items[newIndex].getAttribute('data-theme');
		if(dataTheme) {
			if(slideshow.navigation) slideshow.navigation[0].parentElement.setAttribute('data-theme', dataTheme);
			if(slideshow.controls[0]) slideshow.controls[0].parentElement.setAttribute('data-theme', dataTheme);
		} else {
			if(slideshow.navigation) slideshow.navigation[0].parentElement.removeAttribute('data-theme');
			if(slideshow.controls[0]) slideshow.controls[0].parentElement.removeAttribute('data-theme');
		}
	};

	function emitSlideshowEvent(slideshow, eventName, detail) {
		var event = new CustomEvent(eventName, {detail: detail});
		slideshow.element.dispatchEvent(event);
	};

	function updateAriaLive(slideshow) {
		slideshow.ariaLive.innerHTML = 'Item '+(slideshow.selectedSlide + 1)+' of '+slideshow.items.length;
	};

	function externalControlSlide(slideshow, button) { // control slideshow using external element
		button.addEventListener('click', function(event){
			var index = button.getAttribute('data-index');
			if(!index || index == slideshow.selectedSlide + 1) return;
			event.preventDefault();
			showNewItem(slideshow, index - 1, false);
		});
	};

	Slideshow.defaults = {
    element : '',
    navigation : true,
    autoplay : false,
    autoplayInterval: 5000,
    swipe: false
  };

	window.Slideshow = Slideshow;
	
	//initialize the Slideshow objects
	var slideshows = document.getElementsByClassName('js-slideshow');
	if( slideshows.length > 0 ) {
		for( var i = 0; i < slideshows.length; i++) {
			(function(i){
				var navigation = (slideshows[i].getAttribute('data-navigation') && slideshows[i].getAttribute('data-navigation') == 'off') ? false : true,
					autoplay = (slideshows[i].getAttribute('data-autoplay') && slideshows[i].getAttribute('data-autoplay') == 'on') ? true : false,
					autoplayInterval = (slideshows[i].getAttribute('data-autoplay-interval')) ? slideshows[i].getAttribute('data-autoplay-interval') : 5000,
					swipe = (slideshows[i].getAttribute('data-swipe') && slideshows[i].getAttribute('data-swipe') == 'on') ? true : false;
				new Slideshow({element: slideshows[i], navigation: navigation, autoplay : autoplay, autoplayInterval : autoplayInterval, swipe : swipe});
			})(i);
		}
	}
}());

(function() {
	var ExpGallery = function(element) {
	  this.element = element;
	  this.slideshow = this.element.getElementsByClassName('js-exp-lightbox__body')[0];
	  this.slideshowList = this.element.getElementsByClassName('js-exp-lightbox__slideshow')[0];
	  this.slideshowId = this.element.getAttribute('id')
	  this.gallery = document.querySelector('[data-controls="'+this.slideshowId+'"]');
	  this.galleryItems = this.gallery.getElementsByClassName('js-exp-gallery__item');
	  this.lazyload = this.gallery.getAttribute('data-placeholder');
	  initLightboxMarkup(this);
	  lazyLoadLightbox(this);
	  initSlideshow(this);
	  initModal(this);
	  initModalEvents(this);
	};
  
	function initLightboxMarkup(gallery) {
	  // create items inside lightbox - modal slideshow
	  var slideshowContent = '';
	  for(var i = 0; i < gallery.galleryItems.length; i++) {
		var caption = gallery.galleryItems[i].getElementsByClassName('js-exp-gallery__caption'),
		  image = gallery.galleryItems[i].getElementsByTagName('img')[0],
		  caption = gallery.galleryItems[i].getElementsByClassName('js-exp-gallery__caption');
		// details
		var src = image.getAttribute('data-src');
		if(!src) src = image.getAttribute('src');
		var altAttr = image.getAttribute('alt')
		altAttr = altAttr ? 'alt="'+altAttr+'"' : '';
		var draggable = gallery.slideshow.getAttribute('data-swipe') == 'on' ? 'draggable="false" ondragstart="return false;"' : '';
		var imgBlock = gallery.lazyload 
		  ? '<img data-src="'+src+'" data-loading="lazy" src="'+gallery.lazyload+'" '+altAttr+' '+draggable+'>'
		  : '<img src="'+src+'" data-loading="lazy" '+draggable+'>';
  
		var captionBlock = caption.length > 0
		  ? '<figcaption class="exp-lightbox__caption">'+caption[0].textContent+'</figcaption>'
		  : '';
  
		slideshowContent = slideshowContent + '<li class="slideshow__item js-slideshow__item"><figure class="exp-lightbox__media"><div class="exp-lightbox__media-outer"><div class="exp-lightbox__media-inner">'+imgBlock+'</div></div>'+captionBlock+'</li>';
	  }
	  gallery.slideshowList.innerHTML = slideshowContent;
	  gallery.slides = gallery.slideshowList.getElementsByClassName('js-slideshow__item');
  
	  // append the morphing image - we will animate it from the selected slide to the final position (and viceversa)
	  var imgMorph = document.createElement("div");
	  Util.setAttributes(imgMorph, {'aria-hidden': 'true', 'class': 'exp-lightbox__clone-img-wrapper js-exp-lightbox__clone-img-wrapper', 'data-exp-morph': gallery.slideshowId});
	  imgMorph.innerHTML = '<svg><defs><clipPath id="'+gallery.slideshowId+'-clip"><rect/></clipPath></defs><image height="100%" width="100%" clip-path="url(#'+gallery.slideshowId+'-clip)"></image></svg>';
	  document.body.appendChild(imgMorph);
	  gallery.imgMorph = document.querySelector('.js-exp-lightbox__clone-img-wrapper[data-exp-morph="'+gallery.slideshowId+'"]');
	  gallery.imgMorphSVG = gallery.imgMorph.getElementsByTagName('svg')[0];
	  gallery.imgMorphRect = gallery.imgMorph.getElementsByTagName('rect')[0];
	  gallery.imgMorphImg = gallery.imgMorph.getElementsByTagName('image')[0];
	  
	  // append image for zoom in effect
	  if(gallery.slideshow.getAttribute('data-zoom') == 'on') {
		var zoomImg = document.createElement("div");
		Util.setAttributes(zoomImg, {'aria-hidden': 'true', 'class': 'exp-lightbox__zoom exp-lightbox__zoom--no-transition js-exp-lightbox__zoom'});
		zoomImg.innerHTML = '<img>';
		gallery.element.appendChild(zoomImg);
		gallery.zoomImg = gallery.element.getElementsByClassName('js-exp-lightbox__zoom')[0];
	  }
	};
  
	function lazyLoadLightbox(gallery) {
	  // lazyload media of selected slide/prev slide/next slide
	  gallery.slideshow.addEventListener('newItemSelected', function(event){
		// 'newItemSelected' is emitted by the Slideshow object when a new slide is selected
		gallery.selectedSlide = event.detail;
		lazyLoadSlide(gallery);
	  });
	};
  
	function lazyLoadSlide(gallery) {
	  setSlideMedia(gallery, gallery.selectedSlide);
	  setSlideMedia(gallery, gallery.selectedSlide + 1);
	  setSlideMedia(gallery, gallery.selectedSlide - 1);
	};
  
	function setSlideMedia(gallery, index) {
	  if(index < 0) index = gallery.slides.length - 1;
	  if(index > gallery.slides.length - 1) index = 0;
	  var imgs = gallery.slides[index].querySelectorAll('img[data-src]');
	  for(var i = 0; i < imgs.length; i++) {
		imgs[i].src = imgs[i].getAttribute('data-src');
	  }
	};
  
	function initSlideshow(gallery) { 
	  if(gallery.slides.length <= 1) {
		hideSlideshowElements(gallery);
		return;
	  } 
	  var swipe = (gallery.slideshow.getAttribute('data-swipe') && gallery.slideshow.getAttribute('data-swipe') == 'on') ? true : false;
	  gallery.slideshowObj = new Slideshow({element: gallery.slideshow, navigation: false, autoplay : false, swipe : swipe});
	};
  
	function hideSlideshowElements(gallery) { // hide slideshow controls if gallery is composed by one item only
	  var slideshowNav = gallery.element.getElementsByClassName('js-slideshow__control');
	  if(slideshowNav.length > 0) {
		for(var i = 0; i < slideshowNav.length; i++) Util.addClass(slideshowNav[i], 'is-hidden');
	  }
	};
  
	function initModal(gallery) {
	  Util.addClass(gallery.element, 'exp-lightbox--no-transition'); // add no-transition class to lightbox - used to select the first visible slide
	  gallery.element.addEventListener('modalIsClose', function(event){ // add no-transition class
		Util.addClass(gallery.element, 'exp-lightbox--no-transition');
		gallery.imgMorph.style = '';
	  });
	  // trigger modal lightbox
	  gallery.gallery.addEventListener('click', function(event){
		openModalLightbox(gallery, event);
	  });
	};
  
	function initModalEvents(gallery) {
	  if(gallery.zoomImg) { // image zoom
		gallery.slideshow.addEventListener('click', function(event){
		  if(event.target.tagName.toLowerCase() == 'img' && event.target.closest('.js-slideshow__item') && !gallery.modalSwiping) modalZoomImg(gallery, event.target);
		});
  
		gallery.zoomImg.addEventListener('click', function(event){
		  modalZoomImg(gallery, false);
		});
  
		gallery.element.addEventListener('modalIsClose', function(event){
		  modalZoomImg(gallery, false); // close zoom-in image if open
		  closeModalAnimation(gallery);
		});
	  }
  
	  if(!gallery.slideshowObj) return;
  
	  if(gallery.slideshowObj.options.swipe) { // close gallery when you swipeUp/SwipeDown
		gallery.slideshowObj.element.addEventListener('swipeUp', function(event){
		  closeModal(gallery);
		});
		gallery.slideshowObj.element.addEventListener('swipeDown', function(event){
		  closeModal(gallery);
		});
	  }
	  
	  if(gallery.zoomImg && gallery.slideshowObj.options.swipe) {
		gallery.slideshowObj.element.addEventListener('swipeLeft', function(event){
		  gallery.modalSwiping = true;
		});
		gallery.slideshowObj.element.addEventListener('swipeRight', function(event){
		  gallery.modalSwiping = true;
		});
		gallery.slideshowObj.element.addEventListener('newItemVisible', function(event){
		  gallery.modalSwiping = false;
		});
	  }
	};
  
	function openModalLightbox(gallery, event) {
	  var item = event.target.closest('.js-exp-gallery__item');
	  if(!item) return;
	  gallery.selectedSlide = Util.getIndexInArray(gallery.galleryItems, item);
	  setSelectedItem(gallery);
	  lazyLoadSlide(gallery);
	  if(animationSupported) { // start expanding animation
		window.requestAnimationFrame(function(){
		  animateSelectedImage(gallery);
		  openModal(gallery, item);
		});
	  } else { // no expanding animation -> show modal
		openModal(gallery, item);
		Util.removeClass(gallery.element, 'exp-lightbox--no-transition');
	  }
	};
  
	function setSelectedItem(gallery) {
	  // if a specific slide was selected -> make sure to show that item first
	  var lastSelected = gallery.slideshow.getElementsByClassName('slideshow__item--selected');
	  if(lastSelected.length > 0 ) Util.removeClass(lastSelected[0], 'slideshow__item--selected');
	  Util.addClass(gallery.slides[gallery.selectedSlide], 'slideshow__item--selected');
	  if(gallery.slideshowObj) gallery.slideshowObj.selectedSlide = gallery.selectedSlide;
	};
  
	function openModal(gallery, item) {
	  gallery.element.dispatchEvent(new CustomEvent('openModal', {detail: item}));
	  gallery.modalSwiping = false;
	};
  
	function closeModal(gallery) {
	  gallery.modalSwiping = true;
	  modalZoomImg(gallery, false);
	  gallery.element.dispatchEvent(new CustomEvent('closeModal'));
	};
  
	function closeModalAnimation(gallery) { // modal is already closing -> start image closing animation
	  gallery.selectedSlide = gallery.slideshowObj ? gallery.slideshowObj.selectedSlide : 0;
	  // on close - make sure last selected image (of the gallery) is in the viewport
	  var boundingRect = gallery.galleryItems[gallery.selectedSlide].getBoundingClientRect();
		  if(boundingRect.top < 0 || boundingRect.top > window.innerHeight) {
			  var windowScrollTop = window.scrollY || document.documentElement.scrollTop;
			  window.scrollTo(0, boundingRect.top + windowScrollTop);
		  }
	  // animate on close
	  animateSelectedImage(gallery, true);
	};
  
	function modalZoomImg(gallery, img) { // toggle zoom-in image
	  if(!gallery.zoomImg) return;
	  var bool = false;
	  if(img) { // open zoom-in image
		gallery.originImg = img;
		gallery.zoomImg.children[0].setAttribute('src', img.getAttribute('src'));
		bool = true;
	  }
	  (animationSupported) 
		? requestAnimationFrame(function(){animateZoomImg(gallery, bool)})
		: Util.toggleClass(gallery.zoomImg, 'exp-lightbox__zoom--is-visible', bool);
	};
  
	function animateZoomImg(gallery, bool) {
	  if(!gallery.originImg) return;
	  
	  var originImgPosition = gallery.originImg.getBoundingClientRect(),
		originStyle = 'translateX('+originImgPosition.left+'px) translateY('+(originImgPosition.top + gallery.zoomImg.scrollTop)+'px) scale('+ originImgPosition.width/gallery.zoomImg.scrollWidth+')',
		finalStyle = 'scale(1)';
  
	  if(bool) {
		gallery.zoomImg.children[0].style.transform = originStyle;
	  } else {
		gallery.zoomImg.addEventListener('transitionend', function cb(){
		  Util.addClass(gallery.zoomImg, 'exp-lightbox__zoom--no-transition');
		  gallery.zoomImg.scrollTop = 0;
		  gallery.zoomImg.removeEventListener('transitionend', cb);
		});
	  }
	  setTimeout(function(){
		Util.removeClass(gallery.zoomImg, 'exp-lightbox__zoom--no-transition');
		Util.toggleClass(gallery.zoomImg, 'exp-lightbox__zoom--is-visible', bool);
		gallery.zoomImg.children[0].style.transform = (bool) ? finalStyle : originStyle;
	  }, 50);
	};
  
	function animateSelectedImage(gallery, bool) { // create morphing image effect
	  var imgInit = gallery.galleryItems[gallery.selectedSlide].getElementsByTagName('img')[0],
		imgInitPosition = imgInit.getBoundingClientRect(),
		imgFinal = gallery.slides[gallery.selectedSlide].getElementsByTagName('img')[0],
		imgFinalPosition = imgFinal.getBoundingClientRect();
  
	  // retrieve all animation params
	  var scale = imgFinalPosition.width > imgFinalPosition.height ? imgFinalPosition.height/imgInitPosition.height : imgFinalPosition.width/imgInitPosition.width;
	  var initHeight = imgFinalPosition.width > imgFinalPosition.height ? imgInitPosition.height : imgFinalPosition.height/scale,
		initWidth = imgFinalPosition.width > imgFinalPosition.height ? imgFinalPosition.width/scale : imgInitPosition.width;
  
	  var initTranslateY = (imgInitPosition.height - initHeight)/2,
		initTranslateX = (imgInitPosition.width - initWidth)/2,
		initTop = imgInitPosition.top + initTranslateY,
		initLeft = imgInitPosition.left + initTranslateX;
  
	  // get final states
	  var translateX = imgFinalPosition.left - imgInitPosition.left,
		translateY = imgFinalPosition.top - imgInitPosition.top;
  
	  var finTranslateX = translateX - initTranslateX,
	  finTranslateY = translateY - initTranslateY;
  
	  var initScaleX = imgInitPosition.width/initWidth,
		initScaleY = imgInitPosition.height/initHeight,
		finScaleX = 1,
		finScaleY = 1;
  
	  if(bool) { // update params if this is a closing animation
		scale = 1/scale;
		finScaleX = initScaleX;
		finScaleY = initScaleY;
		initScaleX = 1;
		initScaleY = 1;
		finTranslateX = -1*finTranslateX;
		finTranslateY = -1*finTranslateY;
		initTop = imgFinalPosition.top;
		initLeft = imgFinalPosition.left;
		initHeight = imgFinalPosition.height;
		initWidth = imgFinalPosition.width;
	  }
  
	  // set initial status
	  gallery.imgMorph.setAttribute('style', 'height: '+initHeight+'px; width: '+initWidth+'px; top: '+initTop+'px; left: '+initLeft+'px;');
	  gallery.imgMorphSVG.setAttribute('viewbox', '0 0 '+initWidth+' '+initHeight);
	  Util.setAttributes(gallery.imgMorphImg, {'xlink:href': imgInit.getAttribute('src'), 'href': imgInit.getAttribute('src')});
	  Util.setAttributes(gallery.imgMorphRect, {'style': 'height: '+initHeight+'px; width: '+initWidth+'px;', 'transform': 'translate('+(initWidth/2)*(1 - initScaleX)+' '+(initHeight/2)*(1 - initScaleY)+') scale('+initScaleX+','+initScaleY+')'});
  
	  // reveal image and start animation
	  Util.addClass(gallery.imgMorph, 'exp-lightbox__clone-img-wrapper--is-visible');
	  Util.addClass(gallery.slideshowList, 'slideshow__content--is-hidden');
	  Util.addClass(gallery.galleryItems[gallery.selectedSlide], 'exp-gallery-item-hidden');
  
	  gallery.imgMorph.addEventListener('transitionend', function cb(event){ // reset elements once animation is over
		if(event.propertyName.indexOf('transform') < 0) return;
			  Util.removeClass(gallery.element, 'exp-lightbox--no-transition');
		Util.removeClass(gallery.imgMorph, 'exp-lightbox__clone-img-wrapper--is-visible');
		Util.removeClass(gallery.slideshowList, 'slideshow__content--is-hidden');
		gallery.imgMorph.removeAttribute('style');
		gallery.imgMorphRect.removeAttribute('style');
		gallery.imgMorphRect.removeAttribute('transform');
		gallery.imgMorphImg.removeAttribute('href');
		gallery.imgMorphImg.removeAttribute('xlink:href');
		Util.removeClass(gallery.galleryItems[gallery.selectedSlide], 'exp-gallery-item-hidden');
			  gallery.imgMorph.removeEventListener('transitionend', cb);
	  });
  
	  // trigger expanding/closing animation
	  gallery.imgMorph.style.transform = 'translateX('+finTranslateX+'px) translateY('+finTranslateY+'px) scale('+scale+')';
	  animateRectScale(gallery.imgMorphRect, initScaleX, initScaleY, finScaleX, finScaleY, initWidth, initHeight);
	};
  
	function animateRectScale(rect, scaleX, scaleY, finScaleX, finScaleY, width, height) {
	  var currentTime = null,
		duration =  parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--exp-gallery-animation-duration'))*1000 || 300;
  
	  var animateScale = function(timestamp){  
		if (!currentTime) currentTime = timestamp;         
		var progress = timestamp - currentTime;
		if(progress > duration) progress = duration;
  
		var valX = easeOutQuad(progress, scaleX, finScaleX-scaleX, duration),
		  valY = easeOutQuad(progress, scaleY, finScaleY-scaleY, duration);
  
		rect.setAttribute('transform', 'translate('+(width/2)*(1 - valX)+' '+(height/2)*(1 - valY)+') scale('+valX+','+valY+')');
		if(progress < duration) {
		  window.requestAnimationFrame(animateScale);
		}
	  };
  
	  function easeOutQuad(t, b, c, d) {
		t /= d;
		return -c * t*(t-2) + b;
	  };
	  
	  window.requestAnimationFrame(animateScale);
	};
  
	function keyboardNavigateLightbox(gallery, direction) {
	  if(!Util.hasClass(gallery.element, 'modal--is-visible')) return;
	  if(!document.activeElement.closest('.js-exp-lightbox__body') && document.activeElement.closest('.js-modal')) return;
	  if(!gallery.slideshowObj) return;
	  (direction == 'next') ? gallery.slideshowObj.showNext() : gallery.slideshowObj.showPrev();
	};
  
	window.ExpGallery = ExpGallery;
  
	// init ExpGallery objects
	var expGalleries = document.getElementsByClassName('js-exp-lightbox'),
	  animationSupported = window.requestAnimationFrame && !Util.osHasReducedMotion();
	if( expGalleries.length > 0 ) {
	  var expGalleriesArray = [];
	  for( var i = 0; i < expGalleries.length; i++) {
		(function(i){ expGalleriesArray.push(new ExpGallery(expGalleries[i]));})(i);
  
		// Lightbox gallery navigation with keyboard
		window.addEventListener('keydown', function(event){
		  if(event.keyCode && event.keyCode == 39 || event.key && event.key.toLowerCase() == 'arrowright') {
			updateLightbox('next');
		  } else if(event.keyCode && event.keyCode == 37 || event.key && event.key.toLowerCase() == 'arrowleft') {
			updateLightbox('prev');
		  }
		});
  
		function updateLightbox(direction) {
		  for( var i = 0; i < expGalleriesArray.length; i++) {
			(function(i){keyboardNavigateLightbox(expGalleriesArray[i], direction);})(i);
		  };
		};
	  }
	}
  }());


  // File#: _1_drawer
// Usage: codyhouse.co/license
(function() {
	var Drawer = function(element) {
	  this.element = element;
	  this.content = document.getElementsByClassName('js-drawer__body')[0];
	  this.triggers = document.querySelectorAll('[aria-controls="'+this.element.getAttribute('id')+'"]');
	  this.firstFocusable = null;
	  this.lastFocusable = null;
	  this.selectedTrigger = null;
	  this.isModal = Util.hasClass(this.element, 'js-drawer--modal');
	  this.showClass = "drawer--is-visible";
	  this.initDrawer();
	};
  
	Drawer.prototype.initDrawer = function() {
	  var self = this;
	  //open drawer when clicking on trigger buttons
	  if ( this.triggers ) {
		for(var i = 0; i < this.triggers.length; i++) {
		  this.triggers[i].addEventListener('click', function(event) {
			event.preventDefault();
			if(Util.hasClass(self.element, self.showClass)) {
			  self.closeDrawer(event.target);
			  return;
			}
			self.selectedTrigger = event.target;
			self.showDrawer();
			self.initDrawerEvents();
		  });
		}
	  }
  
	  // if drawer is already open -> we should initialize the drawer events
	  if(Util.hasClass(this.element, this.showClass)) this.initDrawerEvents();
	};
  
	Drawer.prototype.showDrawer = function() {
	  var self = this;
	  this.content.scrollTop = 0;
	  Util.addClass(this.element, this.showClass);
	  this.getFocusableElements();
	  Util.moveFocus(this.element);
	  // wait for the end of transitions before moving focus
	  this.element.addEventListener("transitionend", function cb(event) {
		Util.moveFocus(self.element);
		self.element.removeEventListener("transitionend", cb);
	  });
	  this.emitDrawerEvents('drawerIsOpen', this.selectedTrigger);
	};
  
	Drawer.prototype.closeDrawer = function(target) {
	  Util.removeClass(this.element, this.showClass);
	  this.firstFocusable = null;
	  this.lastFocusable = null;
	  if(this.selectedTrigger) this.selectedTrigger.focus();
	  //remove listeners
	  this.cancelDrawerEvents();
	  this.emitDrawerEvents('drawerIsClose', target);
	};
  
	Drawer.prototype.initDrawerEvents = function() {
	  //add event listeners
	  this.element.addEventListener('keydown', this);
	  this.element.addEventListener('click', this);
	};
  
	Drawer.prototype.cancelDrawerEvents = function() {
	  //remove event listeners
	  this.element.removeEventListener('keydown', this);
	  this.element.removeEventListener('click', this);
	};
  
	Drawer.prototype.handleEvent = function (event) {
	  switch(event.type) {
		case 'click': {
		  this.initClick(event);
		}
		case 'keydown': {
		  this.initKeyDown(event);
		}
	  }
	};
  
	Drawer.prototype.initKeyDown = function(event) {
	  if( event.keyCode && event.keyCode == 27 || event.key && event.key == 'Escape' ) {
		//close drawer window on esc
		this.closeDrawer(false);
	  } else if( this.isModal && (event.keyCode && event.keyCode == 9 || event.key && event.key == 'Tab' )) {
		//trap focus inside drawer
		this.trapFocus(event);
	  }
	};
  
	Drawer.prototype.initClick = function(event) {
	  //close drawer when clicking on close button or drawer bg layer 
	  if( !event.target.closest('.js-drawer__close') && !Util.hasClass(event.target, 'js-drawer') ) return;
	  event.preventDefault();
	  this.closeDrawer(event.target);
	};
  
	Drawer.prototype.trapFocus = function(event) {
	  if( this.firstFocusable == document.activeElement && event.shiftKey) {
		//on Shift+Tab -> focus last focusable element when focus moves out of drawer
		event.preventDefault();
		this.lastFocusable.focus();
	  }
	  if( this.lastFocusable == document.activeElement && !event.shiftKey) {
		//on Tab -> focus first focusable element when focus moves out of drawer
		event.preventDefault();
		this.firstFocusable.focus();
	  }
	}
  
	Drawer.prototype.getFocusableElements = function() {
	  //get all focusable elements inside the drawer
	  var allFocusable = this.element.querySelectorAll('[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex]:not([tabindex="-1"]), [contenteditable], audio[controls], video[controls], summary');
	  this.getFirstVisible(allFocusable);
	  this.getLastVisible(allFocusable);
	};
  
	Drawer.prototype.getFirstVisible = function(elements) {
	  //get first visible focusable element inside the drawer
	  for(var i = 0; i < elements.length; i++) {
		if( elements[i].offsetWidth || elements[i].offsetHeight || elements[i].getClientRects().length ) {
		  this.firstFocusable = elements[i];
		  return true;
		}
	  }
	};
  
	Drawer.prototype.getLastVisible = function(elements) {
	  //get last visible focusable element inside the drawer
	  for(var i = elements.length - 1; i >= 0; i--) {
		if( elements[i].offsetWidth || elements[i].offsetHeight || elements[i].getClientRects().length ) {
		  this.lastFocusable = elements[i];
		  return true;
		}
	  }
	};
  
	Drawer.prototype.emitDrawerEvents = function(eventName, target) {
	  var event = new CustomEvent(eventName, {detail: target});
	  this.element.dispatchEvent(event);
	};
  
	window.Drawer = Drawer;
  
	//initialize the Drawer objects
	var drawer = document.getElementsByClassName('js-drawer');
	if( drawer.length > 0 ) {
	  for( var i = 0; i < drawer.length; i++) {
		(function(i){new Drawer(drawer[i]);})(i);
	  }
	}
  }());