// File#: _1_google-maps
// Usage: codyhouse.co/license
function initGoogleMap() {
  var contactMap = document.getElementsByClassName("js-google-maps");
  if (contactMap.length > 0) {
    for (var i = 0; i < contactMap.length; i++) {
      initContactMap(contactMap[i]);
    }
  }
}

var mapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#dfdfdf",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

function initContactMap(wrapper) {
  var coordinate = wrapper.getAttribute("data-coordinates").split(",");
  var markers = JSON.parse(wrapper.getAttribute("data-markers"));

  var map = new google.maps.Map(wrapper, {
    zoom: 16,
    center: { lat: Number(coordinate[0]), lng: Number(coordinate[1]) },
    styles: mapStyle,
    disableDefaultUI: true,
  });
  // var marker = new google.maps.Marker({
  //   position: { lat: Number(coordinate[0]), lng: Number(coordinate[1]) },
  //   map: map,
  // });

  markers.forEach(function (marker) {
    var infowindow = new google.maps.InfoWindow({
      content: marker.title
    });
    var pin = new google.maps.Marker({
      position: { lat: Number(marker.latitude), lng: Number(marker.longitude) },
      title: marker.title,
      icon: marker.marker_afbeelding
    });

    pin.setMap(map);

    pin.addListener('click', function() {
      infowindow.open(map, pin);
    });
  });
}
