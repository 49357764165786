// File#: _2_tabs-v2
// Usage: codyhouse.co/license

// File#: _1_tabs
// Usage: codyhouse.co/license
(function() {
	var Tab = function(element) {
		this.element = element;
		this.tabList = this.element.getElementsByClassName('js-tabs__controls')[0];
		this.listItems = this.tabList.getElementsByTagName('li');
		this.triggers = this.tabList.getElementsByTagName('a');
		this.panelsList = this.element.getElementsByClassName('js-tabs__panels')[0];
		this.panels = Util.getChildrenByClassName(this.panelsList, 'js-tabs__panel');
		this.hideClass = 'is-hidden';
		this.customShowClass = this.element.getAttribute('data-show-panel-class') ? this.element.getAttribute('data-show-panel-class') : false;
		this.initTab();
	};

	Tab.prototype.initTab = function() {
		//set initial aria attributes
		
		this.tabList.setAttribute('role', 'tablist');
		for( var i = 0; i < this.triggers.length; i++) {
			var bool = (i == 0),
				panelId = this.panels[i].getAttribute('id');
			this.listItems[i].setAttribute('role', 'presentation');
			Util.setAttributes(this.triggers[i], {'role': 'tab', 'aria-selected': bool, 'aria-controls': panelId, 'id': 'tab-'+panelId});
			Util.addClass(this.triggers[i], 'js-tabs__trigger'); 
			Util.setAttributes(this.panels[i], {'role': 'tabpanel', 'aria-labelledby': 'tab-'+panelId});
			Util.toggleClass(this.panels[i], this.hideClass, !bool);

			if(!bool) this.triggers[i].setAttribute('tabindex', '-1'); 
		}

		//listen for Tab events
		this.initTabEvents();
	};

	Tab.prototype.initTabEvents = function() {
		var self = this;
		//click on a new tab -> select content
		this.tabList.addEventListener('click', function(event) {
			console.log(this.tabList)
			if( event.target.closest('.js-tabs__trigger') ) self.triggerTab(event.target.closest('.js-tabs__trigger'), event);
		});

		//arrow keys to navigate through tabs 
		this.tabList.addEventListener('keydown', function(event) {
			if( !event.target.closest('.js-tabs__trigger') ) return;
			if( event.keyCode && event.keyCode == 39 || event.key && event.key == 'ArrowRight' ) {
				self.selectNewTab('next');
			} else if( event.keyCode && event.keyCode == 37 || event.key && event.key == 'ArrowLeft' ) {
				self.selectNewTab('prev');
			}
		});
	};

	Tab.prototype.selectNewTab = function(direction) {
		var selectedTab = this.tabList.querySelector('[aria-selected="true"]'),
			index = Util.getIndexInArray(this.triggers, selectedTab);
		index = (direction == 'next') ? index + 1 : index - 1;
		//make sure index is in the correct interval 
		//-> from last element go to first using the right arrow, from first element go to last using the left arrow
		if(index < 0) index = this.listItems.length - 1;
		if(index >= this.listItems.length) index = 0;	
		this.triggerTab(this.triggers[index]);
		this.triggers[index].focus();
	};

	Tab.prototype.triggerTab = function(tabTrigger, event) {
		var self = this;
		event && event.preventDefault();	
		var index = Util.getIndexInArray(this.triggers, tabTrigger);
		//no need to do anything if tab was already selected
		if(this.triggers[index].getAttribute('aria-selected') == 'true') return;
		
		for( var i = 0; i < this.triggers.length; i++) {
			var bool = (i == index);
			Util.toggleClass(this.panels[i], this.hideClass, !bool);
			if(this.customShowClass) Util.toggleClass(this.panels[i], this.customShowClass, bool);
			this.triggers[i].setAttribute('aria-selected', bool);
			bool ? this.triggers[i].setAttribute('tabindex', '0') : this.triggers[i].setAttribute('tabindex', '-1');
		}
	};
	
	//initialize the Tab objects
	var tabs = document.getElementsByClassName('js-tabs');
	if( tabs.length > 0 ) {
		for( var i = 0; i < tabs.length; i++) {
			(function(i){new Tab(tabs[i]);})(i);
		}
	}
}());

// File#: _1_choice-buttons
// Usage: codyhouse.co/license
(function() {
	var ChoiceButton = function(element) {
	  this.element = element;
	  this.btns = this.element.getElementsByClassName('js-choice-btn');
	  this.inputs = getChoiceInput(this);
	  this.isRadio = this.inputs[0].type.toString() == 'radio';
	  resetCheckedStatus(this); // set initial classes
	  initChoiceButtonEvent(this); // add listeners
	};
  
	function getChoiceInput(element) { // store input elements in an object property
	  var inputs = [];
	  for(var i = 0; i < element.btns.length; i++) {
		inputs.push(element.btns[i].getElementsByTagName('input')[0]);
	  }
	  return inputs;
	};
  
	function initChoiceButtonEvent(choiceBtn) {
	  choiceBtn.element.addEventListener('click', function(event){ // update status on click
		if(Util.getIndexInArray(choiceBtn.inputs, event.target) > -1) return; // triggered by change in input element -> will be detected by the 'change' event
  
		var selectedBtn = event.target.closest('.js-choice-btn');
		if(!selectedBtn) return;
		var index = Util.getIndexInArray(choiceBtn.btns, selectedBtn);
		if(choiceBtn.isRadio && choiceBtn.inputs[index].checked) { // radio input already checked
		  choiceBtn.inputs[index].focus(); // move focus to input element
		  return; 
		}
  
		choiceBtn.inputs[index].checked = !choiceBtn.inputs[index].checked;
		choiceBtn.inputs[index].dispatchEvent(new CustomEvent('change')); // trigger change event
		choiceBtn.inputs[index].focus(); // move focus to input element
	  });
  
	  for(var i = 0; i < choiceBtn.btns.length; i++) {(function(i){ // change + focus events
		choiceBtn.inputs[i].addEventListener('change', function(event){
		
		  choiceBtn.isRadio ? resetCheckedStatus(choiceBtn) : resetSingleStatus(choiceBtn, i);
		});
  
		choiceBtn.inputs[i].addEventListener('focus', function(event){
		  resetFocusStatus(choiceBtn, i, true);
		});
  
		choiceBtn.inputs[i].addEventListener('blur', function(event){
		  resetFocusStatus(choiceBtn, i, false);
		});
	  })(i);}
	};
  
	function resetCheckedStatus(choiceBtn) {
	  for(var i = 0; i < choiceBtn.btns.length; i++) {
		resetSingleStatus(choiceBtn, i);
	  }
	};
  
	function resetSingleStatus(choiceBtn, index) { // toggle .choice-btn--checked class
	  
	  Util.toggleClass(choiceBtn.btns[index], 'choice-btn--checked', choiceBtn.inputs[index].checked);
	};
  
	function resetFocusStatus(choiceBtn, index, bool) { // toggle .choice-btn--focus class
	  Util.toggleClass(choiceBtn.btns[index], 'choice-btn--focus', bool);
	};
  
	//initialize the ChoiceButtons objects
	  var choiceButton = document.getElementsByClassName('js-choice-btns');
	  if( choiceButton.length > 0 ) {
		  for( var i = 0; i < choiceButton.length; i++) {
			  (function(i){new ChoiceButton(choiceButton[i]);})(i);
		  }
	  };
  }());