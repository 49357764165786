//TODO, switch to Vue
window.onload = function () {
  window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  window.axios.defaults.headers.common["X-CSRF-TOKEN"] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

  function closeModal(element) {
    var event = new CustomEvent("closeModal");
    element.dispatchEvent(event);
  }

  var form = document.getElementById("beschikbaarheid-form");
  var nieuwsbriefForm = document.getElementById("nieuwsbrief-form");
  var selectedRooms = { property: "", rooms: [] };
  var afspraakSuccess = $(".afspraak-alert-success");
  var afspraakError = $(".afspraak-alert-error");
  var afspraakMakenBtn = $('.btn-afspraak-maken');
  var selectedRoomsContainer =  $('.js-selected-rooms');
  var selectedRoomsContainerItems =  selectedRoomsContainer.find('.rooms');

  afspraakSuccess.hide();
  afspraakError.hide();
  $('.rooms__title').hide();
  // afspraakMakenBtn.hide();
  

  $(".js-choice-btn").click(function(e) {
    var $this = $(this);

    if ($this.data("rented")) {
      return false;
    }



    if ($this.hasClass("choice-btn--checked")) {
      selectedRooms.rooms = selectedRooms.rooms.filter(function (room) {
        return room !== $this.data("room-name");
      });
    } else {
      selectedRooms.property = $this.data("room-location");
      selectedRooms.rooms.push($this.data("room-name"));
    }
    $("#selected-location-input").attr("value", selectedRooms.property);
    $("#selected-rooms-input").attr("value", selectedRooms.rooms.join(","));

    if (selectedRooms.rooms.length) {
      // afspraakMakenBtn.show();
      selectedRoomsContainerItems.empty();
      selectedRooms.rooms.forEach((room)=>{
       
        selectedRoomsContainerItems.append($('<span class="badge badge--accent text-sm font-normal margin-right-sm margin-bottom-sm">' + room + '</span>'));
      });
    } else {
      selectedRoomsContainerItems.empty();
    }

    if (selectedRooms.rooms.length) {
      $('.rooms__title').show();
    } else {
      $('.rooms__title').hide();
    }

  });

  var submitAfspraakForm = function (event) {
    event.preventDefault();
    axios
      .post(
        form.action,
        new FormData(document.getElementById("beschikbaarheid-form"))
      )
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          afspraakSuccess.show();

          var modal = document.getElementById("afspraak-form");
          //open first modal available in your page
          setTimeout(function () {
            closeModal(modal);
          }, 1500);
        }
      })
      .catch((error) => {
        afspraakError.show();

        console.log(error);
      });
  };

  if (form) {
    form.addEventListener("submit", submitAfspraakForm, true);

  }





  var submitNieuwsbriefForm = function (event) {
    event.preventDefault();

    axios
      .post(
        nieuwsbriefForm.action,
        new FormData(document.getElementById("nieuwsbrief-form"))
      )
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          $('.nieuwsbrief__submit').addClass('btn--success')
          $('.nieuwsbrief__submit').toggleClass('btn--state-b')
          nieuwsbriefForm.reset();

 
          
          // var modal = document.getElementById("niewsbrief-modal");
          // //open first modal available in your page
          // setTimeout(function () {
          //   closeModal(modal);
          // }, 1500);
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };
  if (nieuwsbriefForm) {
    nieuwsbriefForm.addEventListener("submit", submitNieuwsbriefForm, true);

  }

};


// File#: _1_choice-tags
// Usage: codyhouse.co/license
(function() {
  var ChoiceTags = function(element) {
    this.element = element;
    this.labels = this.element.getElementsByClassName('js-choice-tag');
    this.inputs = getChoiceInput(this);
    this.isRadio = this.inputs[0].type.toString() == 'radio';
    this.checkedClass = 'choice-tag--checked';
    initChoiceTags(this);
    initChoiceTagEvent(this);
  }

  function getChoiceInput(element) {
    var inputs = [];
    for(var i = 0; i < element.labels.length; i++) {
      inputs.push(element.labels[i].getElementsByTagName('input')[0]);
    }
    return inputs;
  };

  function initChoiceTags(element) {
    // if tag is selected by default - add checkedClass to the label element
    for(var i = 0; i < element.inputs.length; i++) {
      Util.toggleClass(element.labels[i], element.checkedClass, element.inputs[i].checked);
    }
  };

  function initChoiceTagEvent(element) {
    element.element.addEventListener('change', function(event) {
      var inputIndex = Util.getIndexInArray(element.inputs, event.target);
      if(inputIndex < 0) return;
      Util.toggleClass(element.labels[inputIndex], element.checkedClass, event.target.checked);
      if(element.isRadio && event.target.checked) resetRadioTags(element, inputIndex);
    });
  };

  function resetRadioTags(element, index) {
    // when a radio input is checked - reset all the others
    for(var i = 0; i < element.labels.length; i++) {
      if(i != index) Util.removeClass(element.labels[i], element.checkedClass);
    }
  };

  //initialize the ChoiceTags objects
	var choiceTags = document.getElementsByClassName('js-choice-tags');
	if( choiceTags.length > 0 ) {
		for( var i = 0; i < choiceTags.length; i++) {
			(function(i){new ChoiceTags(choiceTags[i]);})(i);
		}
	};
}());

// File#: _1_adv-multiple-custom-select
// Usage: codyhouse.co/license
window.addEventListener('initTimeSelect', (e)=>{
  var AdvMultiSelect = function(element) {
    this.element = element;
    this.select = this.element.getElementsByTagName('select')[0];
    this.optGroups = this.select.getElementsByTagName('optgroup');
    this.options = this.select.getElementsByTagName('option');
    this.optionData = getOptionsData(this); // create custom templates
    this.selectId = this.select.getAttribute('id');
    this.selectLabel = document.querySelector('[for='+this.selectId+']')
    this.list = this.element.getElementsByClassName('js-advm-select__list')[0];
    // used for keyboard/mouse multiple selection
    this.startSelection = false; 
    this.latestSelection = false;
    // detect touch device
    this.touchDevice = false;
    // reset buttons
    this.resetBtns = (this.selectId) ? document.querySelectorAll('[aria-controls="'+this.selectId +'"]') : [];

    initAdvMultiSelect(this); // init markup
    initAdvMultiSelectEvents(this); // init event listeners
  };

  function getOptionsData(select) {
    var obj = [],
      dataset = select.options[0].dataset;

    function camelCaseToDash( myStr ) {
      return myStr.replace( /([a-z])([A-Z])/g, '$1-$2' ).toLowerCase();
    }
    for (var prop in dataset) {
      if (Object.prototype.hasOwnProperty.call(dataset, prop)) {
        obj.push(camelCaseToDash(prop));
      }
    }
    console.log(obj);
    return obj;
  };

  function initAdvMultiSelect(select) {
    // create custom structure
    createAdvStructure(select);
    // store all custom options and labels
    select.customOptions = select.list.getElementsByClassName('js-advm-select__option');
    select.customLabels = select.list.getElementsByClassName('js-advm-select__label');
    // make custom list focusable
    select.list.setAttribute('tabindex', 0);
    // hide native select and show custom structure
    Util.addClass(select.select, 'is-hidden');
    Util.removeClass(select.list, 'is-hidden');
  };

  function initAdvMultiSelectEvents(select) {
    if(select.selectLabel) {
      // move focus to custom select list when clicking on <select> label
      select.selectLabel.addEventListener('click', function(){
        select.list.focus();
      });
    }

    // new option is selected in custom list
    select.list.addEventListener('click', function(event){
      var target = event.target,
        option = target.closest('.js-advm-select__option');
      if(!option) return;
      mouseSelection(select, option, event);
      select.touchDevice = false;
    });

    select.list.addEventListener('touchend', function(event){ // touch devices
      select.touchDevice = true;
    });

    // keyboard navigation
    select.list.addEventListener('keydown', function(event){
      // use up/down arrows or space key to select new options
     
      if(event.keyCode && event.keyCode == 38 || event.key && event.key.toLowerCase() == 'arrowup') {
        event.preventDefault();
        keyboardSelection(select, 'prev', event);
      } else if(event.keyCode && event.keyCode == 40 || event.key && event.key.toLowerCase() == 'arrowdown') {
        event.preventDefault();
        keyboardSelection(select, 'next', event);
      } else if(event.keyCode && event.keyCode == 32 || event.key && event.key.toLowerCase() == ' ') {
        event.preventDefault();
        var option = document.activeElement.closest('.js-advm-select__option');
        if(!option) return;
        selectOption([option], !(option.hasAttribute('aria-selected') && option.getAttribute('aria-selected') == 'true'));
        select.startSelection = option;
        select.latestSelection = select.startSelection;
      }
    });

    // reset selection
    if(select.resetBtns.length > 0) {
      for(var i = 0; i < select.resetBtns.length; i++) {
        select.resetBtns[i].addEventListener('click', function(event){
          event.preventDefault();
          resetSelect(select);
        });
      }
    }
  };

  function createAdvStructure(select) {
    // store optgroup and option structure
    var optgroup = select.list.querySelector('[role="group"]'),
      option = select.list.querySelector('.js-advm-select__option'),
      optgroupClone = false,
      optgroupLabel = false,
      optionClone = false;
    if(optgroup) {
      optgroupClone = optgroup.cloneNode();
      optgroupLabel = select.list.querySelector('.js-advm-select__label');
    }
    if(option) optionClone = option.cloneNode(true);

    var listCode = '';

    if(select.optGroups.length > 0) {
      for(var i = 0; i < select.optGroups.length; i++) {
        listCode = listCode + getOptGroupCode(select, select.optGroups[i], optgroupClone, optionClone, optgroupLabel, i);
      }
    } else {
      for(var i = 0; i < select.options.length; i++) {
        listCode = listCode + getOptionCode(select, select.options[i], optionClone);
      }
    }

    select.list.innerHTML = listCode;
  };

  function getOptGroupCode(select, optGroup, optGroupClone, optionClone, optgroupLabel, index) {
    if(!optGroupClone || !optionClone) return '';
    var code = '';
    var options = optGroup.getElementsByTagName('option');
    for(var i = 0; i < options.length; i++) {
      code = code + getOptionCode(select, options[i], optionClone);
    }
    if(optgroupLabel) {
      var label = optgroupLabel.cloneNode(true);
      var id = label.getAttribute('id') + '-'+index;
      label.setAttribute('id', id);
      optGroupClone.setAttribute('describedby', id);
      code = label.outerHTML.replace('{optgroup-label}', optGroup.getAttribute('label')) + code;
    } 
    optGroupClone.innerHTML = code;
    return optGroupClone.outerHTML;
  };

  function getOptionCode(select, option, optionClone) {
    console.log('ss', option.value)
    optionClone.setAttribute('data-value', option.value);
    option.selected ? optionClone.setAttribute('aria-selected', 'true') : optionClone.removeAttribute('aria-selected');
    var optionHtml = optionClone.outerHTML;

    optionHtml = optionHtml.replace('{option-label}', option.value);
    for(var i = 0; i < select.optionData.length; i++) {
      optionHtml = optionHtml.replace('{'+select.optionData[i]+'}', option.getAttribute('data-'+select.optionData[i]));
    }
    return optionHtml;
  };

  function mouseSelection(select, option, event) {
    var isSelected = (option.hasAttribute('aria-selected') && option.getAttribute('aria-selected') == 'true'); // option already selected
    if((event.ctrlKey || event.metaKey) && !select.touchDevice) {
      // add/remove clicked element from the selection 
      selectOption([option], !isSelected);
      if(!isSelected) {
        select.startSelection = option;
        select.latestSelection = select.startSelection;
      }
    } else if(event.shiftKey && !isSelected) {
      // select all options between latest selected and clicked option 
      selectInBetween(select, option);
    } else {
      if(!select.touchDevice) {
        // deselect all others and select this one only
        singleSelect(select, option);
      } else {
        // add this item to the selection or deselect it
       selectOption([option], !isSelected);
      }
    }
    select.startSelection = option;
    select.latestSelection = select.startSelection;
    // reset tabindex
    resetTabindex(select);
    // new option has been selected -> update native <select> element
    updateNativeSelect(select);
  };

  function keyboardSelection(select, direction, event) {
    var lastSelectedIndex = -1;
    if(select.latestSelection) {
      lastSelectedIndex = Util.getIndexInArray(select.customOptions, select.latestSelection);
    }
    if(event.ctrlKey || event.metaKey) {
      // in this case we are only moving the focus, so take latest focused option
      var focusedOption = document.activeElement.closest('.js-advm-select__option');
      if(focusedOption) lastSelectedIndex = Util.getIndexInArray(select.customOptions, focusedOption);
    }
    var index = (direction == 'next') ? lastSelectedIndex + 1: lastSelectedIndex - 1;
    if(index < 0 || index >= select.customOptions.length) return;
    var option = select.customOptions[index];

    if(event.ctrlKey || event.metaKey) {
      // ctrl/command + up/down -> move focus
      Util.moveFocus(option);
    } else if(event.shiftKey) {
      // shift + up/down -> select new item
      //remove previously selected options
      selectOption(select.list.querySelectorAll('[aria-selected="true"]'), false);
      // select new options
      selectInBetween(select, option);
      select.latestSelection = option;
    } else {
      // only up/down -> deselect all others and select this one only
      singleSelect(select, option);
      select.startSelection = option;
      select.latestSelection = select.startSelection;
    }
    // reset tabindex
    if(!event.ctrlKey && !event.metaKey) resetTabindex(select);
    // new option has been selected -> update native <select> element
    updateNativeSelect(select);
  };

  function selectInBetween(select, option) {
    // keyboard/mouse navigation + shift -> select optiong between to elements
    var optionsBetween = getOptionsBetween(select, option);
    selectOption(optionsBetween, true);
  };

  function singleSelect(select, option) {
    // select a single option, deselecting all the others
    var selectedOptions = select.list.querySelectorAll('[aria-selected="true"]');
    selectOption(selectedOptions, false);
    selectOption([option], true);
  };

  function selectOption(options, bool) {
    for(var i = 0; i < options.length; i++) {
      (bool) ? options[i].setAttribute('aria-selected', 'true') : options[i].removeAttribute('aria-selected');
    }
    if(bool) options[options.length - 1].scrollIntoView({block: 'nearest'});
  };

  function getOptionsBetween(select, option) {
    var options = [];
    var optIndex = Util.getIndexInArray(select.customOptions, option),
      latestOptIndex = 0;
    if(select.startSelection) {
      latestOptIndex = Util.getIndexInArray(select.customOptions, select.startSelection);
    }
    var min = Math.min(optIndex, latestOptIndex),
      max = Math.max(optIndex, latestOptIndex);
    for(var i = min; i <= max; i++) {
      options.push(select.customOptions[i]);
    }
    return options;
  };

  function updateNativeSelect(select) {
    // update native select element
    for(var i = 0; i < select.customOptions.length; i++) {
      select.options[i].selected = (select.customOptions[i].hasAttribute('aria-selected') && select.customOptions[i].getAttribute('aria-selected') == 'true')
    }
    select.select.dispatchEvent(new CustomEvent('change', {bubbles: true})); // trigger change event
  };

  function resetTabindex(select) {
    var focusableEl = select.list.querySelectorAll('[tabindex]');
    for(var i = 0; i < focusableEl.length; i++) {
      focusableEl[i].removeAttribute('tabindex');
    }
    // move focus on list
    select.list.focus();
  };

  function resetSelect(select) {
    selectOption(select.list.querySelectorAll('[aria-selected="true"]'), false);
    updateNativeSelect(select);
  };

  //initialize the AdvMultiSelect objects
  var advMultiSelect = document.getElementsByClassName('js-advm-select');
  if( advMultiSelect.length > 0 ) {
    for( var i = 0; i < advMultiSelect.length; i++) {
      (function(i){new AdvMultiSelect(advMultiSelect[i]);})(i);
    }
  }


  });