//TODO, switch to Vue
window.onload = function () {
  window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  window.axios.defaults.headers.common["X-CSRF-TOKEN"] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

  function closeModal(element) {
    var event = new CustomEvent("closeModal");
    afspraakSuccess.hide();
    afspraakError.hide();
    element.dispatchEvent(event);
  }

  var afspraakSuccess = $(".afspraak-alert-success");
  var afspraakError = $(".afspraak-alert-error");

  afspraakSuccess.hide();
  afspraakError.hide();

  var submitMeetingroomForm = function (currentForm) {
   
    let formData = new FormData(currentForm);

    let dag = $(currentForm).find('[name=tijd_dag]').val();
    let van = $(currentForm).find('[name=tijd_van]').val();
    let tot = $(currentForm).find('[name=tijd_tot]').val();


    formData.append("ruimte", $(currentForm).find('.ruimte').val())
    formData.append("datum_tijd", dag + 'van' + van + ' tot ' + tot);

    axios
      .post(currentForm.action, formData)
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          afspraakSuccess.show();
          console.log("cc", response);
          var modal = document.getElementById("meetingroom-form-"+ response.data.submission.ruimte + "");
          //open first modal available in your page
          setTimeout(function () {
            closeModal(modal);
          }, 4000);
        return false;
        }
      })
      .catch((error) => {
        afspraakError.show();

        console.log(error);
      });
   
  };

  // if (meetingform) {
  //     meetingform.addEventListener("submit", submitMeetingroomForm, true);

  // }

  $(".meeting-form").each(function () {
    var $this = this;
    $this.addEventListener(
      "submit",
      function (e) {
          e.preventDefault();
        submitMeetingroomForm($this);
      },
      true
    );
  });
};

// File#: _1_custom-select
// Usage: codyhouse.co/license
(function () {
  // NOTE: you need the js code only when using the --custom-dropdown variation of the Custom Select component. Default version does nor require JS.

  var CustomSelect = function (element) {
    this.element = element;
    this.select = this.element.getElementsByTagName("select")[0];
    this.optGroups = this.select.getElementsByTagName("optgroup");
    this.options = this.select.getElementsByTagName("option");
    this.selectedOption = getSelectedOptionText(this);
    this.selectId = this.select.getAttribute("id");
    this.trigger = false;
    this.dropdown = false;
    this.customOptions = false;
    this.arrowIcon = this.element.getElementsByTagName("svg");
    this.label = document.querySelector('[for="' + this.selectId + '"]');

    this.optionIndex = 0; // used while building the custom dropdown

    initCustomSelect(this); // init markup
    initCustomSelectEvents(this); // init event listeners
  };

  function initCustomSelect(select) {
    // create the HTML for the custom dropdown element
    select.element.insertAdjacentHTML(
      "beforeend",
      initButtonSelect(select) + initListSelect(select)
    );

    // save custom elements
    select.dropdown = select.element.getElementsByClassName(
      "js-select__dropdown"
    )[0];
    select.trigger = select.element.getElementsByClassName(
      "js-select__button"
    )[0];
    select.customOptions = select.dropdown.getElementsByClassName(
      "js-select__item"
    );

    // hide default select
    Util.addClass(select.select, "is-hidden");
    if (select.arrowIcon.length > 0) select.arrowIcon[0].style.display = "none";

    // place dropdown
    placeDropdown(select);
  }

  function initCustomSelectEvents(select) {
    // option selection in dropdown
    initSelection(select);

    // click events
    select.trigger.addEventListener("click", function () {
      toggleCustomSelect(select, false);
    });
    if (select.label) {
      // move focus to custom trigger when clicking on <select> label
      select.label.addEventListener("click", function () {
        Util.moveFocus(select.trigger);
      });
    }
    // keyboard navigation
    select.dropdown.addEventListener("keydown", function (event) {
      if (
        (event.keyCode && event.keyCode == 38) ||
        (event.key && event.key.toLowerCase() == "arrowup")
      ) {
        keyboardCustomSelect(select, "prev", event);
      } else if (
        (event.keyCode && event.keyCode == 40) ||
        (event.key && event.key.toLowerCase() == "arrowdown")
      ) {
        keyboardCustomSelect(select, "next", event);
      }
    });
    // native <select> element has been updated -> update custom select as well
    select.element.addEventListener("select-updated", function (event) {
      resetCustomSelect(select);
    });
  }

  function toggleCustomSelect(select, bool) {
    var ariaExpanded;
    if (bool) {
      ariaExpanded = bool;
    } else {
      ariaExpanded =
        select.trigger.getAttribute("aria-expanded") == "true"
          ? "false"
          : "true";
    }
    select.trigger.setAttribute("aria-expanded", ariaExpanded);
    if (ariaExpanded == "true") {
      var selectedOption = getSelectedOption(select);
      Util.moveFocus(selectedOption); // fallback if transition is not supported
      select.dropdown.addEventListener("transitionend", function cb() {
        Util.moveFocus(selectedOption);
        select.dropdown.removeEventListener("transitionend", cb);
      });
      placeDropdown(select); // place dropdown based on available space
    }
  }

  function placeDropdown(select) {
    // remove placement classes to reset position
    Util.removeClass(
      select.dropdown,
      "select__dropdown--right select__dropdown--up"
    );
    var triggerBoundingRect = select.trigger.getBoundingClientRect();
    Util.toggleClass(
      select.dropdown,
      "select__dropdown--right",
      document.documentElement.clientWidth - 5 <
        triggerBoundingRect.left + select.dropdown.offsetWidth
    );
    // check if there's enough space up or down
    var moveUp =
      window.innerHeight - triggerBoundingRect.bottom - 5 <
      triggerBoundingRect.top;
    Util.toggleClass(select.dropdown, "select__dropdown--up", moveUp);
    // check if we need to set a max width
    var maxHeight = moveUp
      ? triggerBoundingRect.top - 20
      : window.innerHeight - triggerBoundingRect.bottom - 20;
    // set max-height based on available space
    select.dropdown.setAttribute(
      "style",
      "max-height: " +
        maxHeight +
        "px; width: " +
        triggerBoundingRect.width +
        "px;"
    );
  }

  function keyboardCustomSelect(select, direction, event) {
    // navigate custom dropdown with keyboard
    event.preventDefault();
    var index = Util.getIndexInArray(
      select.customOptions,
      document.activeElement
    );
    index = direction == "next" ? index + 1 : index - 1;
    if (index < 0) index = select.customOptions.length - 1;
    if (index >= select.customOptions.length) index = 0;
    Util.moveFocus(select.customOptions[index]);
  }

  function initSelection(select) {
    // option selection
    select.dropdown.addEventListener("click", function (event) {
      var option = event.target.closest(".js-select__item");
      if (!option) return;
      selectOption(select, option);
    });
  }

  function selectOption(select, option) {
    if (
      option.hasAttribute("aria-selected") &&
      option.getAttribute("aria-selected") == "true"
    ) {
      // selecting the same option
      select.trigger.setAttribute("aria-expanded", "false"); // hide dropdown
    } else {
      var selectedOption = select.dropdown.querySelector(
        '[aria-selected="true"]'
      );
      if (selectedOption) selectedOption.setAttribute("aria-selected", "false");
      option.setAttribute("aria-selected", "true");
      select.trigger.getElementsByClassName("js-select__label")[0].textContent =
        option.textContent;
      select.trigger.setAttribute("aria-expanded", "false");
      // new option has been selected -> update native <select> element _ arai-label of trigger <button>
      updateNativeSelect(select, option.getAttribute("data-index"));
      updateTriggerAria(select);
    }
    // move focus back to trigger
    select.trigger.focus();
  }

  function updateNativeSelect(select, index) {
    select.select.selectedIndex = index;
    select.select.dispatchEvent(new CustomEvent("change", { bubbles: true })); // trigger change event
  }

  function updateTriggerAria(select) {
    select.trigger.setAttribute(
      "aria-label",
      select.options[select.select.selectedIndex].innerHTML +
        ", " +
        select.label.textContent
    );
  }

  function getSelectedOptionText(select) {
    // used to initialize the label of the custom select button
    var label = "";
    if ("selectedIndex" in select.select) {
      label = select.options[select.select.selectedIndex].text;
    } else {
      label = select.select.querySelector("option[selected]").text;
    }
    return label;
  }

  function initButtonSelect(select) {
    // create the button element -> custom select trigger
    // check if we need to add custom classes to the button trigger
    var customClasses = select.element.getAttribute("data-trigger-class")
      ? " " + select.element.getAttribute("data-trigger-class")
      : "";

    var label =
      select.options[select.select.selectedIndex].innerHTML +
      ", " +
      select.label.textContent;

    var button =
      '<button type="button" class="js-select__button select__button' +
      customClasses +
      '" aria-label="' +
      label +
      '" aria-expanded="false" aria-controls="' +
      select.selectId +
      '-dropdown"><span aria-hidden="true" class="js-select__label select__label">' +
      select.selectedOption +
      "</span>";
    if (select.arrowIcon.length > 0 && select.arrowIcon[0].outerHTML) {
      var clone = select.arrowIcon[0].cloneNode(true);
      Util.removeClass(clone, "select__icon");
      button = button + clone.outerHTML;
    }

    return button + "</button>";
  }

  function initListSelect(select) {
    // create custom select dropdown
    var list =
      '<div class="js-select__dropdown select__dropdown" aria-describedby="' +
      select.selectId +
      '-description" id="' +
      select.selectId +
      '-dropdown">';
    list = list + getSelectLabelSR(select);
    if (select.optGroups.length > 0) {
      for (var i = 0; i < select.optGroups.length; i++) {
        var optGroupList = select.optGroups[i].getElementsByTagName("option"),
          optGroupLabel =
            '<li><span class="select__item select__item--optgroup">' +
            select.optGroups[i].getAttribute("label") +
            "</span></li>";
        list =
          list +
          '<ul class="select__list" role="listbox">' +
          optGroupLabel +
          getOptionsList(select, optGroupList) +
          "</ul>";
      }
    } else {
      list =
        list +
        '<ul class="select__list" role="listbox">' +
        getOptionsList(select, select.options) +
        "</ul>";
    }
    return list;
  }

  function getSelectLabelSR(select) {
    if (select.label) {
      return (
        '<p class="sr-only" id="' +
        select.selectId +
        '-description">' +
        select.label.textContent +
        "</p>"
      );
    } else {
      return "";
    }
  }

  function resetCustomSelect(select) {
    // <select> element has been updated (using an external control) - update custom select
    var selectedOption = select.dropdown.querySelector(
      '[aria-selected="true"]'
    );
    if (selectedOption) selectedOption.setAttribute("aria-selected", "false");
    var option = select.dropdown.querySelector(
      '.js-select__item[data-index="' + select.select.selectedIndex + '"]'
    );
    option.setAttribute("aria-selected", "true");
    select.trigger.getElementsByClassName("js-select__label")[0].textContent =
      option.textContent;
    select.trigger.setAttribute("aria-expanded", "false");
    updateTriggerAria(select);
  }

  function getOptionsList(select, options) {
    var list = "";
    for (var i = 0; i < options.length; i++) {
      var selected = options[i].hasAttribute("selected")
        ? ' aria-selected="true"'
        : ' aria-selected="false"';
      list =
        list +
        '<li><button type="button" class="reset js-select__item select__item select__item--option" role="option" data-value="' +
        options[i].value +
        '" ' +
        selected +
        ' data-index="' +
        select.optionIndex +
        '">' +
        options[i].text +
        "</button></li>";
      select.optionIndex = select.optionIndex + 1;
    }
    return list;
  }

  function getSelectedOption(select) {
    var option = select.dropdown.querySelector('[aria-selected="true"]');
    if (option) return option;
    else return select.dropdown.getElementsByClassName("js-select__item")[0];
  }

  function moveFocusToSelectTrigger(select) {
    if (!document.activeElement.closest(".js-select")) return;
    select.trigger.focus();
  }

  function checkCustomSelectClick(select, target) {
    // close select when clicking outside it
    if (!select.element.contains(target)) toggleCustomSelect(select, "false");
  }

  //initialize the CustomSelect objects
  var customSelect = document.getElementsByClassName("js-select");
  if (customSelect.length > 0) {
    var selectArray = [];
    for (var i = 0; i < customSelect.length; i++) {
      (function (i) {
        selectArray.push(new CustomSelect(customSelect[i]));
      })(i);
    }

    // listen for key events
    window.addEventListener("keyup", function (event) {
      if (
        (event.keyCode && event.keyCode == 27) ||
        (event.key && event.key.toLowerCase() == "escape")
      ) {
        // close custom select on 'Esc'
        selectArray.forEach(function (element) {
          moveFocusToSelectTrigger(element); // if focus is within dropdown, move it to dropdown trigger
          toggleCustomSelect(element, "false"); // close dropdown
        });
      }
    });
    // close custom select when clicking outside it
    window.addEventListener("click", function (event) {
      selectArray.forEach(function (element) {
        checkCustomSelectClick(element, event.target);
      });
    });
  }
})();
